import React, { useContext, useReducer, useEffect } from "react";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { AppBar, Toolbar, Typography, Paper, Grid, Button, TextField, Checkbox, Hidden, Table, TableBody, TableCell, TablePagination, TableRow } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./UsergroupsStyles";

import CustomDialog from "../customdialog/CustomDialog";
import EnhancedTableHead from "../tableview/EnhancedTableHead";
import EnhancedTableToolbar from "../tableview/EnhancedTableToolbar";

import messages from "./messages.js";

import SearchIcon from "@material-ui/icons/Search";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import {
  ROUTE_USERGROUPS,
  // ROUTE_USERGROUPS_ADD
} from "../../../routes/RouteList";

function desc(a, b, orderBy) {
  let myA, myB;
  orderBy === "userCount" ? (myA = parseInt(a[orderBy])) : (myA = a[orderBy].toLowerCase());
  orderBy === "userCount" ? (myB = parseInt(b[orderBy])) : (myB = b[orderBy].toLowerCase());
  if (myB < myA) {
    return -1;
  }
  if (myB > myA) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const initialState = {
  order: "asc",
  orderBy: "name",
  selected: [],
  page: 0,
  dialog: false,
  searchValue: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "order":
      return { ...state, order: action.order };
    case "orderBy":
      return { ...state, orderBy: action.orderBy };
    case "selected":
      return { ...state, selected: action.selected };
    case "page":
      return { ...state, page: action.page };
    case "dialog":
      return { ...state, dialog: !state.dialog };
    case "searchValue":
      return { ...state, searchValue: action.searchValue };
    default:
      throw new Error();
  }
}

function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Usergroups = hooksObserver(({ intl: { formatMessage }, classes }) => {
  const {
    routingStore: { push },
    userStore: { usergroups: { loadUsergroups, setSearchValue, rowsPerPage, setRowsPerPage }, filteredUsergroups },
    applicationStore: { toggleSnackError },
    authStore: { forceIsLoggedInFalse },
  } = useContext(StoresContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  const rows = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: formatMessage(messages.tableheading1),
      mobileLabel: formatMessage(messages.tableheading1short),
    },
    {
      id: "userCount",
      numeric: true,
      disablePadding: false,
      label: formatMessage(messages.tableheading2),
      mobileLabel: formatMessage(messages.tableheading2short),
    },
    {
      id: "view",
      numeric: true,
      disablePadding: false,
      label: " ",
      mobileLabel: " ",
    },
  ];

  useEffect(() => {
    const fetchUsergroups = async () => {
      try {
        await loadUsergroups();
      } catch (e) {
        console.log("Error::usergroups fetch: ", e.toString());
        switch (e.response.status) {
          case 401:
            forceIsLoggedInFalse();
            break;
          default:
            toggleSnackError();
            console.log("TODO::usergroups fetch: We still need to catch the following error: ", e.response.status);
            break;
        }
      }
    };
    fetchUsergroups();
  }, []);

  useEffect(() => {
    setSearchValue(state.searchValue);
  }, [state.searchValue]);

  function handleRequestSort(event, property) {
    const myOrderBy = property;
    let myOrder = "desc";

    if (state.orderBy === property && state.order === "desc") {
      myOrder = "asc";
    }

    dispatch({ type: "order", order: myOrder });
    dispatch({ type: "orderBy", orderBy: myOrderBy });
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      let mySelection = [];
      filteredUsergroups.map((n) => {
        n.role !== "Beheerder" ? mySelection.push(n.id) : null;
      });
      if (mySelection.length === state.selected.length) {
        dispatch({ type: "selected", selected: [] });
      } else {
        dispatch({ type: "selected", selected: mySelection });
      }
      return;
    }
    dispatch({ type: "selected", selected: [] });
  }

  function handleClick(event, id) {
    const selectedIndex = state.selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(state.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(state.selected.slice(1));
    } else if (selectedIndex === state.selected.length - 1) {
      newSelected = newSelected.concat(state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(state.selected.slice(0, selectedIndex), state.selected.slice(selectedIndex + 1));
    }

    dispatch({ type: "selected", selected: newSelected });
  }

  function handleChangePage(event, page) {
    dispatch({ type: "page", page: page });
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  function isSelected(id) {
    return state.selected.indexOf(id) !== -1;
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsergroups.length - state.page * rowsPerPage);

  function handleOpen() {
    return dispatch({ type: "dialog" });
  }

  function deleteMyUsers() {
    /*deleteUsers(state.selected);
    dispatch({ type: "dialog" });
    dispatch({ type: "selected", selected: [] });*/
  }

  function handleSearch(e) {
    dispatch({ type: "searchValue", searchValue: e.target.value });
  }

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <SearchIcon className={classes.block} color="inherit" />
            </Grid>
            <Grid item xs data-test-id="tf-search">
              <TextField
                fullWidth
                placeholder={formatMessage(messages.search)}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
                value={state.searchValue}
                onChange={handleSearch}
              />
            </Grid>
            {/*<Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.addUser}
                onClick={() => push(ROUTE_USERGROUPS_ADD)}
              >
                <GroupAddIcon />
              </Button>
            </Grid>*/}
          </Grid>
        </Toolbar>
      </AppBar>
      <EnhancedTableToolbar numSelected={state.selected.length} buttonClick={handleOpen} />
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle" data-test-id="usergroup-table">
          <EnhancedTableHead
            numSelected={state.selected.length}
            order={state.order}
            orderBy={state.orderBy}
            onSelectAllClick={() => {} /*handleSelectAllClick*/}
            onRequestSort={handleRequestSort}
            rowCount={filteredUsergroups.length}
            rows={rows}
            classes={classes}
          />
          <TableBody>
            {stableSort(filteredUsergroups, getSorting(state.order, state.orderBy))
              .slice(state.page * rowsPerPage, state.page * rowsPerPage + rowsPerPage)
              .map((n, index) => {
                const isSelect = isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={null /*event => handleClick(event, n.id)*/}
                    role="checkbox"
                    aria-checked={isSelect}
                    tabIndex={-1}
                    key={n.name}
                    selected={Math.abs(index % 2) === 1}
                    className={classes.tableRow}
                  >
                    <TableCell padding="checkbox" data-test-id="col-checkbox">
                      <Checkbox checked={isSelect} disabled={true} />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none" data-test-id="col-name">
                      <Typography variant="body1" className={classes.wordBreak}>
                        {ucFirst(n.name)}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right" data-test-id="col-count">
                      <Typography variant="body1">{n.userCount}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right" data-test-id="col-details">
                      <Button
                        data-test-id="btn-details"
                        className={classes.detailButton}
                        color="primary"
                        size="small"
                        variant="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          push(`${ROUTE_USERGROUPS}/details/${n.id}`);
                        }}
                      >
                        <Hidden xsDown>{formatMessage(messages.details)} </Hidden>
                        <ChevronRightIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 60 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredUsergroups.length}
        rowsPerPage={rowsPerPage}
        page={state.page}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={formatMessage(messages.rowsamount)}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
      />
      <CustomDialog
        open={state.dialog}
        setopen={handleOpen}
        title={formatMessage(messages.deletedialogtitle)}
        type={"danger"}
        confirm={formatMessage(messages.deletedialogconfirm)}
        confirmaction={deleteMyUsers}
        cancel={formatMessage(messages.deletedialogcancel)}
      >
        {formatMessage(messages.deletedialogtext1)}
        <strong>{state.selected.length}</strong> {formatMessage(messages.deletedialogtext2)}
      </CustomDialog>
    </Paper>
  );
});

export default injectIntl(withStyles(styles)(Usergroups));
