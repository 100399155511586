import React, { FunctionComponent, useState } from "react";

import { Button, Card, CardActions, CardContent, CardHeader, Grid, Icon, InputAdornment, Link, TextField, Typography } from "@material-ui/core";
import Lock from "@material-ui/icons/Lock";
import Mail from "@material-ui/icons/Mail";
import Send from "@material-ui/icons/Send";
import styles from "./LoginStyles";
import { withStyles } from "@material-ui/styles";
require("./style/logo.css");

const Login = ({ classes, messages, urlForgotPassword, doLogin, enableForgotPassword = true }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const validateEmail = () => {
    if (email.length < 1) return false;
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  const handleValidation = () => {
    return email.length > 0 && !validateEmail() && password.length > 0;
  };

  const handleKeyPress = (e) => {
    if (/enter/gi.test(e.key) && handleValidation()) {
      handleClick();
    }
  };

  const handleClick = async () => {
    try {
      const { success, error } = await doLogin(email, password);
      console.log({ success, error });
    } catch (error) {
      setMessage(messages.notFound ?? "Combinatie e-mail en wachtwoord niet gekend.");
    }
  };

  return (
    <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: "90vh" }}>
      <Card className={classes.card}>
        <CardHeader avatar={<Icon className={"icon-logo-orbit-black"} />} title={messages.title} />
        <CardContent>
          <TextField
            required
            id="email"
            inputProps={{
              "data-test-id": "email",
            }}
            label={messages.labelEmail}
            helperText={messages.labelEmailHelper}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyPress}
            error={validateEmail()}
            margin="normal"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Mail />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            id="password"
            inputProps={{
              "data-test-id": "password",
            }}
            label={messages.labelPassword}
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyPress}
            margin="normal"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
            }}
          />
          <Typography data-test-id="error-message" className={classes.loginError}>
            {message}
          </Typography>
        </CardContent>
        <CardActions className={classes.action}>
          <Button id="btn_login" data-test-id="submit" onClick={(e) => handleClick()} disabled={!handleValidation()} color="primary" className={classes.button} variant="contained">
            <Send className={classes.extendedIcon} />
            {messages.button}
          </Button>
          {enableForgotPassword && (
            <Typography>
              <Link data-test-id="forgot-link" href={urlForgotPassword} target="_blank" className={classes.link}>
                {messages.forgotPassword}
              </Link>
            </Typography>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default withStyles(styles)(Login);
