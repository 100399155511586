import { MuiThemeProvider } from "@material-ui/core/styles";
import { StoresContext } from "contexts";
import { createBrowserHistory } from "history";
import { inject } from "mobx-react";
import { observer as hooksObserver } from "mobx-react-lite";
import { syncHistoryWithStore } from "mobx-react-router";
import React, { useContext, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Router } from "react-router";
import { translationMessages } from "./i18n";
import { light } from "./layout/theme.js";
import AppRoute from "./routes/AppRoute";
import { getGenericSettings } from "./services/api";

const App = ({ routingStore, uiStore: { locale } }) => {
  const browserHistory = createBrowserHistory();
  const history = syncHistoryWithStore(browserHistory, routingStore);

  return (
    <IntlProvider locale={locale} messages={translationMessages[locale]}>
      <Router history={history}>
        <ThemeApp />
      </Router>
    </IntlProvider>
  );
};

const ThemeApp = hooksObserver(() => {
  const {
    applicationStore: { setGenericSettings },
  } = useContext(StoresContext);

  useEffect(() => {
    (async () => {
      const genericSettings = await getGenericSettings();
      setGenericSettings(genericSettings);
    })();
  }, []);

  return (
    <MuiThemeProvider theme={light}>
      <AppRoute />
    </MuiThemeProvider>
  );
});

export default inject("routingStore", "uiStore", "userStore")(App);
