import React, { useContext, useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Input,
  ListItemText,
  Snackbar,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./AddUserStyles";

import { ROUTE_USERS } from "../../../routes/RouteList";

import messages from "./messages.js";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const { DEFAULT_LANGUAGE = "nl_BE" } = window?.env ?? {};

const AddUser = hooksObserver(({ intl: { formatMessage }, classes }) => {
  const {
    userStore: { usergroups: { loadUsergroups }, users: { addUser }, usergrouplist },
    uiStore: { locale },
    authStore: { forceIsLoggedInFalse },
    routingStore: { push },
    applicationStore: { toggleSnackError },
  } = useContext(StoresContext);

  const [error, setError] = useState("");
  const [naam, setNaam] = useState("");
  const [voornaam, setVoornaam] = useState("");
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
  const [selectedUsergroups, setSelectedUsergroups] = useState([]);

  useEffect(() => {
    const loadUsrgrps = async () => {
      try {
        await loadUsergroups();
      } catch (e) {
        console.log("Error::load usergroups: ", e.toString());
        switch (e.response.status) {
          case 401:
            forceIsLoggedInFalse();
            break;
          default:
            toggleSnackError();
            console.log("TODO::load usergroups: We still need to catch the following error: ", e.response.status);
            break;
        }
      }
    };
    loadUsrgrps();
  }, []);

  useEffect(() => {
    if (usergrouplist.length > 0) {
      setSelectedUsergroups([usergrouplist[0].id]);
    }
  }, [usergrouplist]);

  async function submitUser() {
    let user = {
      firstName: voornaam,
      lastName: naam,
      age: 11,
      email: email,
      country: "BE",
      timeZone: "Europe/Brussels",
      language,
      isAdmin: isAdmin,
      userGroupIds: selectedUsergroups,
    };
    try {
      await addUser(user);
      setError("");
      push(ROUTE_USERS);
    } catch (e) {
      console.log("Error::add user: ", e.toString());
      switch (e.response.status) {
        case 400:
          setError(formatMessage(messages.adduserusedemail));
          document.getElementById("email").blur();
          break;
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::add user: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }

  function checkValidity() {
    let form = document.getElementById("form");
    form.reportValidity() ? submitUser() : null; // zie https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/reportValidity
  }

  function handleSelectedUsergroupsChange(e) {
    setSelectedUsergroups(e.target.value);
  }

  function handleSelectedUsergroupsChangeMultiple(e) {
    const { options } = e.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedUsergroups(value);
  }
  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6" color="inherit">
                {formatMessage(messages.addusertitle)}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        <form id="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="naam"
                inputProps={{
                  "data-test-id": "name",
                }}
                label={formatMessage(messages.addusername)}
                type="text"
                fullWidth
                required
                value={naam}
                onChange={(e) => setNaam(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="voornaam"
                inputProps={{
                  "data-test-id": "firstname",
                }}
                label={formatMessage(messages.adduserfirstname)}
                type="text"
                fullWidth
                required
                value={voornaam}
                onChange={(e) => setVoornaam(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="email"
                inputProps={{
                  "data-test-id": "email",
                }}
                label={formatMessage(messages.adduseremail)}
                type="email"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <FormControl className={classes.formControl} fullWidth margin="normal">
                <InputLabel id="language-select-label">{formatMessage(messages.adduserlanguageLabel)}</InputLabel>
                <Select
                  value={language}
                  fullWidth
                  labelId="-select-label"
                  id="language-select"
                  onChange={(event) => {
                    console.log("change langauge", event.target.value);
                    setLanguage(event.target.value);
                  }}
                >
                  <MenuItem value={"nl_BE"}>{formatMessage(messages.adduserDutch)}</MenuItem>
                  <MenuItem value={"fr_BE"}>{formatMessage(messages.adduserFrench)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControlLabel
                control={<Checkbox data-test-id={isAdmin ? "is-admin" : "not-admin"} checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)} value="checkedB" color="primary" />}
                label={formatMessage(messages.userdetailprofile)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl className={classes.formControl} fullWidth margin="normal">
                <InputLabel htmlFor="select-multiple-checkbox">{formatMessage(messages.userdetailselectusergroups)}</InputLabel>
                <Select
                  inputProps={{
                    "data-test-id": "usergroup",
                  }}
                  multiple
                  value={selectedUsergroups}
                  onChange={handleSelectedUsergroupsChange}
                  input={<Input id="select-multiple-checkbox" />}
                  fullWidth
                  renderValue={(selected) => {
                    let names = [];
                    usergrouplist.map((usergrp) => {
                      if (selected.indexOf(usergrp.id) > -1) {
                        names.push(usergrp.name);
                      }
                    });
                    const text = names.join(", ");
                    return text;
                  }}
                  MenuProps={MenuProps}
                >
                  {usergrouplist.map((usergroup) => (
                    <MenuItem key={usergroup.id} value={usergroup.id} data-test-id={usergroup.name}>
                      <Checkbox checked={selectedUsergroups.indexOf(usergroup.id) > -1} />
                      <ListItemText primary={usergroup.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid className={classes.formButtons} container direction="row-reverse" spacing={2}>
            <Grid item>
              <Button data-test-id="submit-button" variant="contained" color="primary" className={classes.button} onClick={checkValidity}>
                {formatMessage(messages.addusersave)}
              </Button>
            </Grid>
            <Grid item>
              <Button data-test-id="cancel-button" color="secondary" className={classes.button} onClick={() => push(ROUTE_USERS)}>
                {formatMessage(messages.addusercancel)}
              </Button>
            </Grid>
            <Grid item>
              <Typography>{error}</Typography>
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
});

export default injectIntl(withStyles(styles)(AddUser));
