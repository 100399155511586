import axios from "axios";
import { tokenExpired } from "./auth";

const { API_URL = "http://localhost:3000" } = window?.env;

export const setDefaultHeader = (token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  tokenExpired(token);
};

export const getGenericSettings = async () => {
  const { data } = await axios.get(API_URL + "setting");
  return data;
};

export const getUsers = async () => {
  const {
    data: { data, errors, message },
  } = await axios.get(API_URL + "users");
  return data;
};

export const getUser = async (userId, loadUsergroups) => {
  const {
    data: { data, errors, message },
  } = await axios.get(API_URL + "users/" + userId + "/usergroups/" + loadUsergroups);
  return data;
};

export const createUser = async (user) => {
  const {
    data: { data, errors, message },
  } = await axios.post(API_URL + "users", user);
  return data;
};

export const pushUser = async (userId, user) => {
  const {
    data: { data, errors, message },
  } = await axios.put(API_URL + "users/" + userId, user);
  return data;
};

export const pushUsersInUsergroup = async (body, usergroupId) => {
  const {
    data: { data, errors, message },
  } = await axios.post(API_URL + "usergroups/" + usergroupId, body);
  return data;
};

export const removeUser = async (userId) => {
  const {
    data: { data, errors, message },
  } = await axios.delete(API_URL + "users/" + userId);
  return data;
};

export const addAdRoleLink = async (roleId, adRole) => {
  const {
    data: { data, errors, message },
  } = await axios.post(`${API_URL}roles/${roleId}/ad`, { adRole });
  return data;
};

export const removeAdRoleLink = async (roleId, adRoleId) => {
  const {
    data: { data, errors, message },
  } = await axios.delete(`${API_URL}roles/${roleId}/ad/${adRoleId}`);
  return data;
};

export const removeUsers = async (body) => {
  const {
    data: { data, errors, message },
  } = await axios.delete(API_URL + "users", {
    data: body,
  });
  return data;
};

export const getUsergroups = async () => {
  const {
    data: { data, errors, message },
  } = await axios.get(API_URL + "usergroups");
  return data;
};

export const getUsergroup = async (usergroupId) => {
  const {
    data: { data, errors, message },
  } = await axios.get(API_URL + "usergroups/" + usergroupId);
  return data;
};

export const removeUserFromUsergroup = async (usergroupId, userId) => {
  const {
    data: { data, errors, message },
  } = await axios.delete(API_URL + "usergroups/" + usergroupId + "/user/" + userId);
  return data;
};

export const getApps = async () => {
  const {
    data: { data, errors, message },
  } = await axios.get(API_URL + "apps/admin");
  return data;
};

export const getProfiles = async (appId) => {
  const {
    data: { data, errors, message },
  } = await axios.get(API_URL + "roles/app/" + appId);
  return data;
};

export const getProfile = async (profileId) => {
  const {
    data: { data, errors, message },
  } = await axios.get(API_URL + "roles/" + profileId);
  return data;
};

export const getExtensions = async (appId) => {
  const {
    data: { data, errors, message },
  } = await axios.get(API_URL + "roles/app/" + appId);
  return data;
};

export const getMunicipalities = async () => {
  const {
    data: { data, errors, message },
  } = await axios.get(API_URL + "municipalities");
  return data;
};

export const getMunicipality = async (munId) => {
  const {
    data: { data, errors, message },
  } = await axios.get(API_URL + "municipalities/" + munId);
  return data;
};

export const loadNotificationLegger = async () => {
  const {
    data: { data, errors, message },
  } = await axios.get(API_URL + "municipalities/leggerNotifications");
  return data;
};

export const pushNotificationLegger = async (newData) => {
  const {
    data: { data, errors, message },
  } = await axios.put(API_URL + "municipalities/leggerNotifications", { data: newData });
  return data;
};

export const getSubscriptions = async () => {
  const {
    data: { data, errors, message },
  } = await axios.get(API_URL + "subscriptions");
  return data;
};

export const pushMunicipality = async (munId, municipality) => {
  const {
    data: { data, errors, message },
  } = await axios.put(API_URL + "municipalities/" + munId, municipality);
  return data;
};

export const pushImportUrbain = async (niscode, jaar, file) => {
  const formData = new FormData();
  formData.append("legger", file, "legger.zip");
  formData.append("niscode", niscode);
  formData.append("year", jaar);
  const {
    data: { data, errors, message },
  } = await axios.post(API_URL + "import/import-legger-data", formData, {
    Headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  return data;
};

export const pushImportCrab = async (file) => {
  const formData = new FormData();
  formData.append("legger", file, "kad_adressen.dbf");
  const {
    data: { data, errors, message },
  } = await axios.post(API_URL + "import/import-crab-adr", formData, {
    Headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  return data;
};

export const getParcelsForNis = async (nis) => {
  const {
    data: { data, errors, message },
  } = await axios.get(API_URL + "municipalities/getParcelsForNis/" + nis);
  return data;
};
