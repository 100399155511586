import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  Grid,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Input,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./UserDetailsStyles";

import { ROUTE_USERS } from "../../../routes/RouteList";

import CustomDialog from "../customdialog/CustomDialog";

import messages from "./messages.js";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserDetails = hooksObserver(({ match: { params }, intl: { formatMessage }, classes }) => {
  const {
    routingStore: { push },
    userStore: { usergroups: { loadUsergroups }, users: { loadUser, activeUser, saveUser, deleteUser }, usergrouplist },
    applicationStore: { toggleSnackError },
    authStore: { user },
  } = useContext(StoresContext);

  const [dialog, setDialog] = useState(false);
  const [error, setError] = useState("");
  const [naam, setNaam] = useState("");
  const [voornaam, setVoornaam] = useState("");
  const [language, setLanguage] = useState("");
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedUsergroups, setSelectedUsergroups] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([loadUser(params.id, true), loadUsergroups()]);
      } catch (e) {
        console.log("Error::user details fetch: ", e.toString());
        switch (e.response.status) {
          case 401:
            forceIsLoggedInFalse();
            break;
          default:
            toggleSnackError();
            console.log("TODO::user details fetch: We still need to catch the following error: ", e.response.status);
            break;
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (activeUser !== null) {
      setNaam(activeUser.lastName);
      setVoornaam(activeUser.firstName);
      setEmail(activeUser.email);
      setIsAdmin(activeUser.isAdmin);
      setLanguage(activeUser.language);
      // check voor usergroup omdat save geen usergroup teruggeeft maar activeUser wel aanpast
      if (activeUser.userGroup !== undefined && activeUser.userGroup !== null) {
        const selected = [];
        activeUser.userGroup.forEach((usergrp) => {
          selected.push(usergrp.id);
        });
        setSelectedUsergroups(selected);
      }
    }
  }, [activeUser]);

  function handleOpen() {
    return setDialog(!dialog);
  }

  async function deleteThisUser() {
    try {
      await deleteUser(activeUser.id);
      handleOpen();
      push(ROUTE_USERS);
    } catch (e) {
      console.log("Error::user details delete: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::user details delete: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }

  async function submitUser() {
    let user = {
      firstName: voornaam,
      lastName: naam,
      email: email,
      isAdmin: isAdmin,
      language: language,
      userGroupIds: selectedUsergroups,
    };
    try {
      await saveUser(activeUser.id, user);
      setError(formatMessage(messages.userdetailmessagesuccess));
    } catch (e) {
      console.log("Error::user details save: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::user details save: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }

  function checkValidity() {
    let form = document.getElementById("form");
    form.reportValidity() ? submitUser() : null; // zie https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/reportValidity
  }

  function handleSelectedUsergroupsChange(e) {
    setSelectedUsergroups(e.target.value);
  }

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography variant="h6" color="inherit">
                {formatMessage(messages.userdetailtitle)}
              </Typography>
            </Grid>
            <Grid item>
              <Button data-test-id="delete-user" disabled={activeUser !== null && activeUser.id === user.id} color="secondary" className={classes.button} onClick={handleOpen}>
                {(activeUser !== null && activeUser.id === user.id && formatMessage(messages.userdetaildeleteownuser)) || formatMessage(messages.userdetaildelete)}
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        <form id="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="naam"
                inputProps={{
                  "data-test-id": "name",
                }}
                label={formatMessage(messages.userdetailname)}
                type="text"
                fullWidth
                required
                value={naam}
                onChange={(e) => setNaam(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="voornaam"
                inputProps={{
                  "data-test-id": "firstname",
                }}
                label={formatMessage(messages.userdetailfirstname)}
                type="text"
                fullWidth
                required
                value={voornaam}
                onChange={(e) => setVoornaam(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="email"
                inputProps={{
                  "data-test-id": "email",
                }}
                disabled
                label={formatMessage(messages.userdetailemail)}
                type="email"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl className={classes.formControl} fullWidth margin="normal">
                <InputLabel id="language-select-label">{formatMessage(messages.adduserlanguageLabel)}</InputLabel>
                <Select
                  value={language}
                  fullWidth
                  labelId="-select-label"
                  id="language-select"
                  onChange={(event) => {
                    console.log("change langauge", event.target.value);
                    setLanguage(event.target.value);
                  }}
                >
                  <MenuItem value={"nl_BE"}>{formatMessage(messages.adduserDutch)}</MenuItem>
                  <MenuItem value={"fr_BE"}>{formatMessage(messages.adduserFrench)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    data-test-id={isAdmin ? "is-admin" : "not-admin"}
                    disabled={activeUser !== null && activeUser.id === user.id}
                    checked={isAdmin}
                    onChange={() => setIsAdmin(!isAdmin)}
                    value="checkedB"
                    color="primary"
                  />
                }
                label={formatMessage(messages.userdetailprofile)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="select-multiple-checkbox">{formatMessage(messages.userdetailselectusergroups)}</InputLabel>
                <Select
                  inputProps={{
                    "data-test-id": "usergroup",
                  }}
                  data-test-id="tf-usergroup"
                  multiple
                  value={selectedUsergroups}
                  onChange={handleSelectedUsergroupsChange}
                  input={<Input id="select-multiple-checkbox" />}
                  fullWidth
                  renderValue={(selected) => {
                    let names = [];
                    usergrouplist.map((usergrp) => {
                      if (selected.indexOf(usergrp.id) > -1) {
                        names.push(usergrp.name);
                      }
                    });
                    const text = names.join(", ");
                    return text;
                  }}
                  MenuProps={MenuProps}
                >
                  {usergrouplist.map((usergroup) => (
                    <MenuItem key={usergroup.id} value={usergroup.id} data-test-id={usergroup.name}>
                      <Checkbox checked={selectedUsergroups.indexOf(usergroup.id) > -1} />
                      <ListItemText primary={usergroup.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid className={classes.formButtons} container direction="row-reverse" spacing={2}>
            <Grid item>
              <Button data-test-id="submit-button" variant="contained" color="primary" className={classes.button} onClick={checkValidity}>
                {formatMessage(messages.userdetailsave)}
              </Button>
            </Grid>
            <Grid item>
              <Button data-test-id="cancel-button" color="secondary" className={classes.button} onClick={() => push(ROUTE_USERS)}>
                {formatMessage(messages.addusercancel)}
              </Button>
            </Grid>
            <Grid item>
              <Typography>{error}</Typography>
            </Grid>
          </Grid>
        </form>
      </div>
      <CustomDialog
        data-test-id="delete-popup"
        open={dialog}
        setopen={handleOpen}
        title={formatMessage(messages.userdetaildialogtitle)}
        type={"danger"}
        confirm={formatMessage(messages.userdetaildialogconfirm)}
        confirmaction={deleteThisUser}
        cancel={formatMessage(messages.userdetaildialogcancel)}
      >
        {formatMessage(messages.userdetaildialogtext)}
      </CustomDialog>
    </Paper>
  );
});

export default injectIntl(withStyles(styles)(UserDetails));
