import React, { useContext, useReducer, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { CssBaseline, withStyles, Hidden, Snackbar, SnackbarContent, Button } from "@material-ui/core";

import classNames from "classnames";
import { Switch, Route, withRouter } from "react-router";
import ErrorIcon from "@material-ui/icons/Error";

import styles from "./BasePageStyles";
import Header from "../partials/header/Header";
import Sidebar from "../partials/sidebar/Sidebar";
import Dashboard from "../partials/dashboard/Dashboard";
import Usergroups from "../partials/usergroups/Usergroups";
import AddUsergroup from "../partials/usergroups/AddUsergroup";
import UsergroupDetails from "../partials/usergroups/UsergroupDetails";
import Users from "../partials/users/Users";
import AddUser from "../partials/users/AddUser";
import UserDetails from "../partials/users/UserDetails";
import Profiles from "../partials/profiles/Profiles";
import ProfileDetails from "../partials/profiles/ProfileDetails";
import Permissions from "../partials/permissions/Permissions";
import Municipalities from "../partials/municipalities/Municipalities";
import MunicipalityDetails from "../partials/municipalities/MunicipalityDetails";

import { setDefaultHeader } from "../../services/api";

import {
  ROUTE_BASE,
  ROUTE_LOGIN,
  ROUTE_USERGROUPS,
  // ROUTE_USERGROUPS_ADD,
  ROUTE_USERGROUPS_DETAILS,
  ROUTE_USERS,
  ROUTE_USERS_DETAILS,
  ROUTE_USERS_ADD,
  ROUTE_PROFILES,
  ROUTE_PROFILES_DETAILS,
  ROUTE_MUNICIPALITIES,
  ROUTE_MUNICIPALITIES_ADD,
  ROUTE_MUNICIPALITIES_DETAILS,
  ROUTE_CRAB_ADDRESSES,
  ROUTE_NOTIFICATIONS,
  // ROUTE_PERMISSIONS
} from "../../routes/RouteList";
import CrabAddresses from "containers/partials/municipalities/CrabAddresses";
import Notifications from "containers/partials/municipalities/Notifications";

const drawerWidth = 256;

const BasePage = hooksObserver(({ classes, intl: { formatMessage } }) => {
  const {
    applicationStore: { applications: { loadApplications }, snackError, toggleSnackError },
    authStore: { forceIsLoggedInFalse },
  } = useContext(StoresContext);

  useEffect(() => {
    const fetchApps = async () => {
      try {
        await loadApplications();
      } catch (e) {
        console.log("Error::fetch applications: ", e.toString());
        switch (e.response.status) {
          case 401:
            forceIsLoggedInFalse();
            break;
          default:
            toggleSnackError();
            console.log("TODO::add user: We still need to catch the following error: ", e.response.status);
            break;
        }
      }
    };
    fetchApps();
  }, []);

  const [mobileOpen, setMobileOpen] = useState(false);
  //const [snackError, setSnackError] = useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="js">
          <Sidebar PaperProps={{ style: { width: drawerWidth } }} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar PaperProps={{ style: { width: drawerWidth } }} />
        </Hidden>
      </nav>
      <div className={classes.appContent}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <main className={classes.mainContent}>
          <Switch>
            <Route exact path={ROUTE_BASE} component={Dashboard} />
            <Route exact path={ROUTE_USERS} component={Users} />
            <Route exact path={ROUTE_USERS_ADD} component={AddUser} />
            <Route exact path={ROUTE_USERS_DETAILS} component={UserDetails} />
            <Route exact path={ROUTE_USERGROUPS} component={Usergroups} />
            {/*<Route exact path={ROUTE_USERGROUPS_ADD} component={AddUsergroup} />*/}
            <Route exact path={ROUTE_USERGROUPS_DETAILS} component={UsergroupDetails} />
            <Route exact path={ROUTE_PROFILES} component={Profiles} />
            <Route exact path={ROUTE_PROFILES_DETAILS} component={ProfileDetails} />
            {/*<Route exact path={ROUTE_PERMISSIONS} component={Permissions} />*/}
            <Route exact path={ROUTE_MUNICIPALITIES} component={Municipalities} />
            <Route exact path={ROUTE_MUNICIPALITIES_ADD} component={AddUser} />
            <Route exact path={ROUTE_MUNICIPALITIES_DETAILS} component={MunicipalityDetails} />
            <Route exact path={ROUTE_CRAB_ADDRESSES} component={CrabAddresses} />
            <Route exact path={ROUTE_NOTIFICATIONS} component={Notifications} />
          </Switch>
        </main>
      </div>
      <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={snackError}>
        <SnackbarContent
          aria-describedby="message-id"
          className={classes.snackError}
          message={
            <span id="message-id" className={classes.snackMessage}>
              <ErrorIcon className={classes.snackIcon} />
              Er is iets fout gegaan!
            </span>
          }
          action={
            <Button className={classes.snackButton} onClick={toggleSnackError} color="primary" size="small">
              OK
            </Button>
          }
        />
      </Snackbar>
    </div>
  );
});

BasePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectIntl(withRouter(withStyles(styles, { withTheme: true })(BasePage)));
