import {
  AppBar,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  Hidden,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ClearIcon from "@material-ui/icons/Clear";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SearchIcon from "@material-ui/icons/Search";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useContext, useEffect, useReducer } from "react";
import { injectIntl } from "react-intl";
import { ROUTE_USERS, ROUTE_USERS_ADD } from "../../../routes/RouteList";
import CustomDialog from "../customdialog/CustomDialog";
import EnhancedTableHead from "../tableview/EnhancedTableHead";
import EnhancedTableToolbar from "../tableview/EnhancedTableToolbar";
import messages from "./messages.js";

import { withStyles } from "@material-ui/core/styles";
import styles from "./UsersStyles";

function desc(a, b, orderBy) {
  let myA, myB;
  orderBy === "naam" ? (myA = (a["firstName"] + " " + a["lastName"]).toLowerCase()) : typeof a[orderBy] === "string" ? (myA = a[orderBy].toLowerCase()) : (myA = a[orderBy]);
  orderBy === "naam" ? (myB = (b["firstName"] + " " + b["lastName"]).toLowerCase()) : typeof b[orderBy] === "string" ? (myB = b[orderBy].toLowerCase()) : (myB = b[orderBy]);
  if (myB < myA) {
    return -1;
  }
  if (myB > myA) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const initialState = {
  order: "desc",
  orderBy: "isAdmin",
  selected: [],
  page: 0,
  dialog: false,
  usergroupdialog: false,
  addtousergroupdialog: false,
  searchValue: "",
  selectedUsergroup: null,
  failedUsers: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "order":
      return { ...state, order: action.order };
    case "orderBy":
      return { ...state, orderBy: action.orderBy };
    case "selected":
      return { ...state, selected: action.selected };
    case "page":
      return { ...state, page: action.page };
    case "dialog":
      return { ...state, dialog: !state.dialog };
    case "usergroupdialog":
      return { ...state, usergroupdialog: !state.usergroupdialog };
    case "addtousergroupdialog":
      return { ...state, addtousergroupdialog: !state.addtousergroupdialog };
    case "searchValue":
      return { ...state, searchValue: action.searchValue };
    case "selectedUsergroup":
      return { ...state, selectedUsergroup: action.selectedUsergroup };
    case "failedUsers":
      return { ...state, failedUsers: action.failedUsers };
    default:
      throw new Error();
  }
}

const Users = hooksObserver(({ intl: { formatMessage }, classes }) => {
  const {
    routingStore: { push },
    userStore: {
      users: { loadUsers, setSearchValue, rowsPerPage, setRowsPerPage, deleteUsers },
      usergroups: { loadUsergroups, addUsersToUsergroup },
      filteredUsers,
      usergrouplist,
    },
    applicationStore: { toggleSnackError },
    authStore: { user, forceIsLoggedInFalse },
  } = useContext(StoresContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  const rows = [
    {
      id: "volledigenaam",
      numeric: false,
      disablePadding: true,
      label: formatMessage(messages.tableheading1),
      mobileLabel: formatMessage(messages.tableheading1short),
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: formatMessage(messages.tableheading2),
      mobileLabel: "",
      hide: true,
    },
    {
      id: "isAdmin",
      numeric: true,
      disablePadding: false,
      label: formatMessage(messages.tableheading3),
      mobileLabel: formatMessage(messages.tableheading3short),
    },
    {
      id: "view",
      numeric: true,
      disablePadding: false,
      label: " ",
      mobileLabel: " ",
    },
  ];

  useEffect(() => {
    const loadAllData = async () => {
      try {
        await Promise.all([loadUsers(), loadUsergroups()]);
      } catch (e) {
        console.log("Error::fetch users/usergroups: ", e.toString());
        switch (e.response.status) {
          case 401:
            forceIsLoggedInFalse();
            break;
          default:
            toggleSnackError();
            console.log("TODO::fetch users/usergroups: We still need to catch the following error: ", e.response.status);
            break;
        }
      }
    };
    loadAllData();
  }, []);

  useEffect(() => {
    if (usergrouplist.length > 0) {
      dispatch({
        type: "selectedUsergroup",
        selectedUsergroup: usergrouplist[0].id,
      });
    }
  }, [usergrouplist]);

  useEffect(() => {
    setSearchValue(state.searchValue);
  }, [state.searchValue]);

  function handleRequestSort(event, property) {
    const myOrderBy = property;
    let myOrder = "desc";

    if (state.orderBy === property && state.order === "desc") {
      myOrder = "asc";
    }

    dispatch({ type: "order", order: myOrder });
    dispatch({ type: "orderBy", orderBy: myOrderBy });
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      let mySelection = [];
      filteredUsers.map((n) => {
        mySelection.push(n.id);
      });
      if (mySelection.length === state.selected.length) {
        dispatch({ type: "selected", selected: [] });
      } else {
        dispatch({ type: "selected", selected: mySelection });
      }
      return;
    }
    dispatch({ type: "selected", selected: [] });
  }

  function handleClick(event, id) {
    const selectedIndex = state.selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(state.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(state.selected.slice(1));
    } else if (selectedIndex === state.selected.length - 1) {
      newSelected = newSelected.concat(state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(state.selected.slice(0, selectedIndex), state.selected.slice(selectedIndex + 1));
    }

    dispatch({ type: "selected", selected: newSelected });
  }

  function handleChangePage(event, page) {
    dispatch({ type: "page", page: page });
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  function isSelected(id) {
    return state.selected.indexOf(id) !== -1;
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - state.page * rowsPerPage);

  function handleOpen() {
    return dispatch({ type: "dialog" });
  }

  function handleOpenUsergroup() {
    return dispatch({ type: "usergroupdialog" });
  }

  function handleOpenAddToUsergroup() {
    return dispatch({ type: "addtousergroupdialog" });
  }

  async function deleteMyUsers() {
    const listToDelete = state.selected.filter((selected) => selected !== user.id);
    try {
      await deleteUsers(listToDelete);
    } catch (e) {
      console.log("Error::delete users: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::delete users: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
    dispatch({ type: "dialog" });
    dispatch({ type: "selected", selected: [] });
  }

  function handleSearch(e) {
    dispatch({ type: "searchValue", searchValue: e.target.value });
  }

  function handleUsergroupChange(e) {
    dispatch({ type: "selectedUsergroup", selectedUsergroup: e.target.value });
  }

  async function addToGroup() {
    try {
      const usersAddedToUsergroup = await addUsersToUsergroup(state.selected, state.selectedUsergroup);
      /*const selected = state.selected;
      const failed = [];
      selected.forEach(userId => {
        if(usersAddedToUsergroup.users.indexOf(userId) === -1) {
          failed.push(userId);
        } else {
          selected.splice(selected.indexOf(userId), 1);
        }
      })
      const succeeded = state.selected.length;
      dispatch({ type: "selected", selected: selected });*/
      handleOpenUsergroup();
      if (state.selected.length === 1 && usersAddedToUsergroup.users.length === 0) {
        filteredUsers.map((usr) => {
          if (usr.id === state.selected[0]) {
            const arr = [];
            arr.push(usr);
            dispatch({ type: "failedUsers", failedUsers: arr });
          }
        });
        handleOpenAddToUsergroup();
      }
    } catch (e) {
      console.log("Error::add users to usergroup: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::add users to usergroup: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item data-test-id="btn-search">
              <SearchIcon className={classes.block} color="inherit" />
            </Grid>
            <Grid item xs data-test-id="tf-search">
              <TextField
                fullWidth
                placeholder={formatMessage(messages.search)}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
                value={state.searchValue}
                onChange={handleSearch}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" className={classes.addUser} onClick={() => push(ROUTE_USERS_ADD)} data-test-id="btn-add-user">
                <PersonAddIcon />
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <EnhancedTableToolbar
        numSelected={state.selected.length}
        buttonClick={handleOpen}
        notSelectedTooltip={formatMessage(messages.tooltipnotselectedtable)}
        extraAction={handleOpenUsergroup}
        extraIcon={<GroupAddIcon />}
        extraTooltip={formatMessage(messages.addtousergroup)}
      />
      <div className={classes.tableWrapper}>
        <Table data-test-id="user-table" className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={state.selected.length}
            order={state.order}
            orderBy={state.orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={filteredUsers.length}
            rows={rows}
            classes={classes}
          />
          <TableBody>
            {stableSort(filteredUsers, getSorting(state.order, state.orderBy))
              .slice(state.page * rowsPerPage, state.page * rowsPerPage + rowsPerPage)
              .map((n, index) => {
                const isSelect = isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, n.id)}
                    role="checkbox"
                    aria-checked={isSelect}
                    tabIndex={-1}
                    key={n.id}
                    selected={Math.abs(index % 2) === 1}
                    className={classes.tableRow}
                  >
                    <TableCell padding="checkbox" data-test-id="col-checkbox">
                      <Checkbox checked={isSelect} />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none" data-test-id="col-name">
                      <Typography variant="body1">{n.firstName + " " + n.lastName}</Typography>
                      <Hidden mdUp>
                        <Typography variant="caption">{n.isAdmin}</Typography>
                      </Hidden>
                    </TableCell>
                    <Hidden xsDown>
                      <TableCell className={classes.tableCell} align="left" data-test-id="col-email">
                        <Typography
                          variant="body1"
                          className={classes.emailLink}
                          component={"a"}
                          onClick={(e) => {
                            e.stopPropagation();
                            window.location = `mailto:${n.email}`;
                          }}
                        >
                          {n.email}
                        </Typography>
                      </TableCell>
                    </Hidden>
                    <TableCell data-test-id="col-is-admin" className={classes.tableCell} align="right" data-test-id={n.isAdmin ? "data-admin" : "data-no-admin"}>
                      <Hidden xsDown>
                        <Chip
                          className={(n.isAdmin && classes.chipSuccess) || classes.chipPrimary}
                          variant="default"
                          label={(n.isAdmin && <CheckIcon className={classes.adminIcon} />) || <ClearIcon className={classes.adminIcon} />}
                        />
                      </Hidden>
                      <Hidden smUp>
                        <Typography variant="body1" className={(n.isAdmin && classes.colorSuccess) || classes.colorPrimary}>
                          &bull;
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right" data-test-id="col-details">
                      <Button
                        data-test-id="btn-details"
                        className={classes.detailButton}
                        color="primary"
                        size="small"
                        variant="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          push(`${ROUTE_USERS}/details/${n.id}`);
                        }}
                      >
                        <Hidden xsDown>{formatMessage(messages.details)} </Hidden>
                        <ChevronRightIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 60 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={state.page}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={formatMessage(messages.rowsamount)}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
      />
      <CustomDialog
        open={state.dialog}
        setopen={handleOpen}
        title={formatMessage(messages.deletedialogtitle)}
        type={"danger"}
        confirm={formatMessage(messages.deletedialogconfirm)}
        confirmaction={deleteMyUsers}
        cancel={formatMessage(messages.deletedialogcancel)}
      >
        {formatMessage(messages.deletedialogtext1)} <strong>{state.selected.length}</strong> {formatMessage(messages.deletedialogtext2)}
      </CustomDialog>
      <CustomDialog
        open={state.addtousergroupdialog}
        setopen={handleOpenAddToUsergroup}
        title={formatMessage(messages.addtousergroupdialogtitle)}
        type={"alert"}
        confirm={formatMessage(messages.addtousergroupdialogconfirm)}
        confirmaction={handleOpenAddToUsergroup}
        cancel={formatMessage(messages.addtousergroupdialogcancel)}
      >
        {formatMessage(messages.addtousergroupdialogtext1)} <strong>{state.failedUsers.map((failedUser) => failedUser.firstName + " " + failedUser.lastName)}</strong>{" "}
        {formatMessage(messages.addtousergroupdialogtext2)}
      </CustomDialog>
      <CustomDialog
        open={state.usergroupdialog}
        setopen={handleOpenUsergroup}
        title={formatMessage(messages.usergroupdialogtitle)}
        type={"primary"}
        confirm={formatMessage(messages.usergroupdialogconfirm)}
        confirmaction={addToGroup}
        cancel={formatMessage(messages.usergroupdialogcancel)}
      >
        {formatMessage(messages.usergroupdialogtext1)} <strong>{state.selected.length}</strong> {formatMessage(messages.usergroupdialogtext2)}
        <br />
        <br />
        <form>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="usergroup">{formatMessage(messages.usergroupdialogselectusergroup)}</InputLabel>
            <Select value={state.selectedUsergroup} onChange={handleUsergroupChange} input={<Input id="usergroup" />}>
              {usergrouplist.map((usergroup) => {
                return (
                  <MenuItem key={usergroup.id} value={usergroup.id}>
                    {usergroup.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </form>
      </CustomDialog>
    </Paper>
  );
});

export default injectIntl(withStyles(styles)(Users));
