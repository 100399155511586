import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { AppBar, Toolbar, Typography, Paper, Grid, TextField, Chip, Icon, IconButton, Divider } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./ProfileDetailsStyles";
import AddIcon from "@material-ui/icons/Add";
import messages from "./messages.js";
import { removeAdRoleLink, addAdRoleLink } from "services/api";

const ProfileDetails = hooksObserver(({ match: { params }, intl: { formatMessage }, classes }) => {
  const {
    applicationStore: { genericSettings, profiles: { loadProfile, activeProfile }, toggleSnackError },
    authStore: { forceIsLoggedInFalse },
  } = useContext(StoresContext);

  const fetchProfile = async () => {
    try {
      loadProfile(params.id);
    } catch (e) {
      console.log("Error::profile detail fetch: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::profile detail fetch: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const [naam, setNaam] = useState("");
  const [beschrijving, setBeschrijving] = useState("");

  useEffect(() => {
    if (activeProfile !== null) {
      setNaam(activeProfile.name);
      setBeschrijving(activeProfile.description);
    }
  }, [activeProfile]);

  const onRemoveAdLink = async (adRoleId) => {
    await removeAdRoleLink(activeProfile.id, adRoleId);
    fetchProfile();
  };

  const onAddAdLink = async (adRole) => {
    await addAdRoleLink(activeProfile.id, adRole);
    fetchProfile();
  };

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6" color="inherit" data-test-id="profile-details-title">
                {formatMessage(messages.profiledetailtitle)}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        <form id="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="naam"
                label={formatMessage(messages.profiledetailname)}
                data-test-id="profile-details-name"
                type="text"
                fullWidth
                required
                disabled
                value={naam}
                onChange={(e) => setNaam(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="omschrijving"
                label={formatMessage(messages.profiledetaildescription)}
                data-test-id="profile-details-description"
                type="text"
                fullWidth
                required
                disabled
                value={beschrijving}
                onChange={(e) => setBeschrijving(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            {genericSettings.find((obj) => obj.name === "adLogin")?.data?.active && activeProfile?.adRoles && (
              <ActiveDirectory linkedAdRoles={activeProfile.adRoles} onDeleteRole={onRemoveAdLink} onAddAdRole={onAddAdLink} />
            )}
          </Grid>
        </form>
      </div>
    </Paper>
  );
});

export default injectIntl(withStyles(styles)(ProfileDetails));

const ActiveDirectory = ({ linkedAdRoles, onDeleteRole, onAddAdRole }) => {
  const [newAdRole, setNewAdRole] = useState("");

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Typography variant="subtitle2" gutterBottom>
        Gekoppelde Active Directory rollen
      </Typography>
      <div style={{ display: "flex", margin: 8, marginLeft: 0 }}>
        <TextField id="standard-basic" label="" value={newAdRole} onChange={(e) => setNewAdRole(e.target.value)} />
        <IconButton
          onClick={() => {
            if (newAdRole) {
              onAddAdRole(newAdRole);
              setNewAdRole("");
            }
          }}
        >
          <AddIcon />
        </IconButton>
      </div>

      {linkedAdRoles.map((adRole) => (
        <Chip style={{ margin: 4 }} label={adRole.key} onDelete={() => onDeleteRole(adRole.id)} /*  color="secondary" */ />
      ))}
    </Grid>
  );
};
