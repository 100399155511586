import React, { Component, Fragment } from "react";
import { Redirect, Route } from "react-router";
import LoginView from "views/login/LoginView";
import { withErrorBoundary } from "../containers/partials/error-boundary/ErrorBoundary";
import PrivateRoutes from "./PrivateRoutes";

import PublicRoutes from "./PublicRoutes";
import {
  ROUTE_LOGIN,
  ROUTE_BASE,
  ROUTE_USERGROUPS,
  // ROUTE_USERGROUPS_ADD,
  ROUTE_USERGROUPS_DETAILS,
  ROUTE_USERS,
  ROUTE_USERS_ADD,
  ROUTE_USERS_DETAILS,
  ROUTE_PROFILES,
  ROUTE_PROFILES_DETAILS,
  // ROUTE_PERMISSIONS
  ROUTE_MUNICIPALITIES,
  ROUTE_MUNICIPALITIES_ADD,
  ROUTE_MUNICIPALITIES_DETAILS,
  ROUTE_CRAB_ADDRESSES,
  ROUTE_NOTIFICATIONS,
} from "./RouteList";
import BasePage from "../containers/base/BasePage";

class AppRoute extends Component {
  render() {
    return (
      <Fragment>
        <PublicRoutes>
          <Route exact path={ROUTE_LOGIN} component={LoginView} />
          <Redirect to={ROUTE_LOGIN} />
        </PublicRoutes>
        <PrivateRoutes>
          <Route
            exact
            path={[
              ROUTE_USERGROUPS,
              // ROUTE_USERGROUPS_ADD,
              ROUTE_USERGROUPS_DETAILS,
              ROUTE_USERS,
              ROUTE_USERS_ADD,
              ROUTE_USERS_DETAILS,
              ROUTE_PROFILES,
              ROUTE_PROFILES_DETAILS,
              // ROUTE_PERMISSIONS
              ROUTE_MUNICIPALITIES,
              ROUTE_MUNICIPALITIES_ADD,
              ROUTE_MUNICIPALITIES_DETAILS,
              ROUTE_CRAB_ADDRESSES,
              ROUTE_NOTIFICATIONS,
            ]}
            component={BasePage}
          />
          <Redirect to={ROUTE_USERS} />
        </PrivateRoutes>
      </Fragment>
    );
  }
}

export default withErrorBoundary(AppRoute);
