import React, { useEffect, useState } from "react";
import { observer as hooksObserver } from "mobx-react-lite";
import { AppBar, Typography, Paper, Grid, TextField, Button, Checkbox, IconButton, Select, MenuItem, InputLabel, FormControl, Link } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import { datePickerTheme, styles } from "./MunicipalityDetailsStyles";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import messages from "./messages.js";
import { injectIntl } from "react-intl";
import { loadNotificationLegger, pushNotificationLegger } from "services/api";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const TYPES = [
  { label: "Fout", value: "error" },
  { label: "Info", value: "info" },
  { label: "Waarschuwing", value: "warning" },
  { label: "Succesvol", value: "success" },
];

const Notifications = hooksObserver(({ intl: { formatMessage }, classes }) => {
  const [notifications, setNotifications] = useState([]);
  const [remoteNotifications, setRemoteNotifications] = useState(null);
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await loadNotificationLegger();
        setNotifications(data?.notifications || []);
        setRemoteNotifications(data?.notifications || []);
      } catch (e) {
        setError("Er liep iets fout bij het ophalen van de meldingen, probeer het later opnieuw.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleRemoveNotification = (id) => {
    setNotifications(notifications.filter((n) => n.id !== id));
    setChanged(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      setError("");
      const data = await pushNotificationLegger({ notifications });
      if (data?.notifications) {
        setNotifications(data.notifications);
        setRemoteNotifications(data.notifications);
        setChanged(false);
      }
    } catch (er) {
      setError("Er liep iets fout bij het opslaan, probeer het later opnieuw.");
    } finally {
      setLoading(false);
    }
  };

  const handleNew = () => {
    setNotifications([...notifications, { id: new Date().getTime(), body: "", title: "", enabled: true, type: "info", start: null, end: null, url: "" }]);
    setChanged(true);
  };

  const updateNotification = (notif, updatedValues) => {
    const currentNotifications = [...notifications];
    let currentNotification = currentNotifications.find((n) => n.id === notif.id);
    if (currentNotification) {
      Object.keys(updatedValues).reduce((acc, cur) => (acc[cur] = updatedValues[cur]), currentNotification);
      setNotifications(currentNotifications);
      setChanged(true);
    }
  };

  return (
    <Paper className={classes.paperImport}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}></AppBar>
      <div className={classes.contentWrapper} style={{ paddingLeft: 15, paddingRight: 15 }}>
        <form id="notificationsSet">
          {remoteNotifications && !notifications?.length && !error && (
            <Typography>
              Nog geen meldingen ingesteld,{" "}
              <Link href="#" onClick={handleNew}>
                maak een eerste melding
              </Link>
              .
            </Typography>
          )}
          {notifications.map((notif, i) => (
            <Grid key={notif.id} container spacing={3} style={{ marginTop: 20 }}>
              <Grid item xs={1} style={{ textAlign: "left", marginTop: 5 }}>
                <Checkbox disabled={loading} checked={notif.enabled} onChange={(e) => updateNotification(notif, { enabled: e.target.checked })} />
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={datePickerTheme}>
                        <DateTimePicker
                          className={classes.datepicker}
                          autoOk
                          ampm={false}
                          value={notif.start}
                          onChange={(date) => updateNotification(notif, { start: date })}
                          label="Startdatum"
                          fullWidth
                          format="dd/MM/yyyy HH:mm"
                          clearable
                          clearLabel="Wissen"
                          emptyLabel="Niet van toepassing"
                          disabled={loading}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={datePickerTheme}>
                        <DateTimePicker
                          className={classes.datepicker}
                          autoOk
                          ampm={false}
                          value={notif.end}
                          onChange={(date) => updateNotification(notif, { end: date })}
                          label="Einddatum"
                          fullWidth
                          format="dd/MM/yyyy HH:mm"
                          clearable
                          clearLabel="Wissen"
                          emptyLabel="Niet van toepassing"
                          disabled={loading}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginTop: 0 }}>
                  <Grid item xs={4}>
                    <FormControl fullWidth style={{ paddingTop: 21 }} disabled={loading}>
                      <InputLabel id="type">Type</InputLabel>
                      <Select labelId="type" value={notif.type} fullWidth style={{ marginTop: 0 }} onChange={(e) => updateNotification(notif, { type: e.target.value })}>
                        {TYPES.map((type) => (
                          <MenuItem key={type.value} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth disabled={loading}>
                      <TextField
                        id="title"
                        label="Titel"
                        type="text"
                        fullWidth
                        value={notif.title}
                        onChange={(e) => updateNotification(notif, { title: e.target.value })}
                        className={classes.textField}
                        margin="dense"
                        disabled={loading}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: 10 }}>
                  <Grid item xs={12}>
                    <TextField
                      id="body"
                      label="Uitleg"
                      type="text"
                      fullWidth
                      multiline
                      value={notif.body}
                      onChange={(e) => updateNotification(notif, { body: e.target.value })}
                      className={classes.textField}
                      margin="dense"
                      disabled={loading}
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: 10 }}>
                  <Grid item xs={12}>
                    <TextField
                      id="url"
                      label="Meer info URL"
                      type="text"
                      fullWidth
                      value={notif.url}
                      onChange={(e) => updateNotification(notif, { url: e.target.value })}
                      className={classes.textField}
                      margin="dense"
                      disabled={loading}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} style={{ textAlign: "right", marginTop: 5 }}>
                <IconButton style={{ color: "rgb(211, 47, 47)" }} disabled={loading} onClick={() => handleRemoveNotification(notif.id)}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          {(remoteNotifications?.length > 0 || notifications?.length > 0) && (
            <Grid className={classes.formButtons} container direction="row-reverse" spacing={2}>
              <Grid item>
                <Button variant="contained" color="primary" className={classes.button} onClick={handleSave} disabled={!changed || loading}>
                  {formatMessage(messages.municipalitydetailsave)}
                </Button>
              </Grid>
              {notifications?.length > 0 && (
                <Grid item>
                  <Button variant="contained" color="primary" className={classes.button} disabled={loading} startIcon={<AddIcon />} onClick={handleNew}>
                    Voeg nog een melding toe
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
          {error && (
            <Grid container>
              <Typography>{error}</Typography>
            </Grid>
          )}
        </form>
      </div>
    </Paper>
  );
});

export default injectIntl(withStyles(styles)(Notifications));
