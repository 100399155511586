import React, { useContext } from "react";
import PropTypes from "prop-types";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { injectIntl, FormattedMessage } from "react-intl";
import { Switch, Route } from "react-router";
import { AppBar, IconButton, Toolbar, Typography, Icon, Hidden, Grid, withWidth } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import styles from "./HeaderStyles";
import messages from "./messages";
import HeaderTabs from "./HeaderTabs";
import HeaderFab from "../headerfab/HeaderFab";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LanguageIcon from "@material-ui/icons/Language";

require("../../../layout/orbit.css");

import {
  ROUTE_BASE,
  ROUTE_USERGROUPS,
  // ROUTE_USERGROUPS_ADD,
  ROUTE_USERGROUPS_DETAILS,
  ROUTE_USERS,
  ROUTE_USERS_ADD,
  ROUTE_USERS_DETAILS,
  ROUTE_PROFILES,
  ROUTE_PROFILES_DETAILS,
  ROUTE_MUNICIPALITIES,
  ROUTE_MUNICIPALITIES_DETAILS,
  ROUTE_CRAB_ADDRESSES,
  ROUTE_NOTIFICATIONS,
  // ROUTE_PERMISSIONS
} from "../../../routes/RouteList";

const Header = hooksObserver(({ intl: { formatMessage }, width, onDrawerToggle }, classes) => {
  const {
    routingStore: { location, push },
    applicationStore: { apps },
    authStore: { user, doLogout, forceIsLoggedInFalse },
    uiStore: { locale, setLocale },
  } = useContext(StoresContext);
  let approute = null;

  async function logout() {
    try {
      await doLogout();
    } catch (e) {
      console.log("Error::logout: ", e.toString());
      forceIsLoggedInFalse();
    }
  }

  function switchLocale() {
    locale === "nl" ? setLocale("fr") : setLocale("nl");
    window.location.reload();
  }
  const localeTitle = locale === "nl" ? "FR" : "NL";
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer" onClick={onDrawerToggle}>
                  <Icon className={"icon-logo-orbit-black"} />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
          </Grid>
          <Grid item>
            <HeaderFab
              fabmenuid="app-language"
              fabtitle={locale.toUpperCase()}
              fabicon={<LanguageIcon className={classes.customfabicon} />}
              fabactions={[
                {
                  title: localeTitle,
                  funct: switchLocale,
                },
              ]}
            />
          </Grid>
          <Grid item>
            <HeaderFab
              fabmenuid="app-profile"
              fabtitle={user.lastName}
              fabicon={<AccountCircleIcon className={classes.customfabicon} />}
              fabactions={[
                {
                  title: formatMessage(messages.logout),
                  funct: logout,
                },
              ]}
            />
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" data-test-id="header_title">
                <Switch>
                  <Route exact path={ROUTE_BASE}>
                    {formatMessage(messages.dashboard)}
                  </Route>
                  <Route exact path={[ROUTE_USERS, ROUTE_USERS_ADD, ROUTE_USERS_DETAILS, ROUTE_USERGROUPS, ROUTE_USERGROUPS_DETAILS]}>
                    {formatMessage(messages.usermanagement)}
                  </Route>
                  <Route
                    exact
                    path={[
                      ROUTE_PROFILES,
                      ROUTE_PROFILES_DETAILS,
                      // ROUTE_PERMISSIONS
                      ROUTE_MUNICIPALITIES,
                      ROUTE_MUNICIPALITIES_DETAILS,
                      ROUTE_CRAB_ADDRESSES,
                      ROUTE_NOTIFICATIONS,
                    ]}
                  >
                    {apps.map(({ name }) => {
                      if (name === location.pathname.split("/")[1]) {
                        approute = name;
                        return <FormattedMessage key={name} {...(messages[name + "appdisplayname"] ? messages[name + "appdisplayname"] : messages.unknownappdisplayname)} />;
                      }
                    })}
                  </Route>
                </Switch>
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
        <Switch>
          <Route
            exact
            path={["", ROUTE_BASE]}
            component={() => (
              <HeaderTabs
                tabs={[
                  {
                    label: formatMessage(messages.overview),
                    route: ROUTE_BASE,
                    check: () => {
                      return location.pathname.split("/")[1] === "";
                    },
                  },
                  {
                    label: formatMessage(messages.statistics),
                    route: ROUTE_BASE,
                    check: () => {
                      return location.pathname.split("/")[1] === "statistics";
                    },
                  },
                ]}
              />
            )}
          />
          <Route
            exact
            path={[
              ROUTE_USERS,
              ROUTE_USERS_ADD,
              ROUTE_USERS_DETAILS,
              ROUTE_USERGROUPS,
              // ROUTE_USERGROUPS_ADD,
              ROUTE_USERGROUPS_DETAILS,
            ]}
            component={() => (
              <HeaderTabs
                tabs={[
                  {
                    label: formatMessage(messages.users),
                    route: ROUTE_USERS,
                    check: () => {
                      return location.pathname.split("/")[1] === "users";
                    },
                  },
                  {
                    label: formatMessage(messages.usergroups),
                    route: ROUTE_USERGROUPS,
                    check: () => {
                      return location.pathname.split("/")[1] === "usergroups";
                    },
                  },
                ]}
              />
            )}
          />
          <Route
            exact
            path={[
              ROUTE_PROFILES,
              ROUTE_PROFILES_DETAILS,
              // ROUTE_PERMISSIONS
            ]}
            component={() => (
              <HeaderTabs
                tabs={[
                  {
                    label: formatMessage(messages.profielen),
                    route: ROUTE_BASE + approute + "/profiles",
                    check: () => {
                      return location.pathname.split("/")[2] === "profiles";
                    },
                  },
                ]}
              />
            )}
          />
          <Route
            exact
            path={[ROUTE_MUNICIPALITIES, ROUTE_MUNICIPALITIES_DETAILS, ROUTE_CRAB_ADDRESSES, ROUTE_NOTIFICATIONS]}
            component={() => (
              <HeaderTabs
                tabs={[
                  {
                    label: formatMessage(messages.gemeenten),
                    route: ROUTE_BASE + approute + "/municipalities",
                    check: () => {
                      return location.pathname.split("/")[2] === "municipalities";
                    },
                  },
                  {
                    label: formatMessage(messages.crab),
                    route: ROUTE_BASE + approute + "/crab",
                    check: () => {
                      return location.pathname.split("/")[2] === "crab";
                    },
                  },
                  {
                    label: formatMessage(messages.notifications),
                    route: ROUTE_BASE + approute + "/notifications",
                    check: () => {
                      return location.pathname.split("/")[2] === "notifications";
                    },
                  },
                ]}
              />
            )}
          />
        </Switch>
      </AppBar>
    </React.Fragment>
  );
});

export default withWidth()(injectIntl(withStyles(styles)(Header)));
