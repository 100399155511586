import { defineMessages } from "react-intl";

export default defineMessages({
  appname: {
    id: "sidebar.appname",
    defaultMessage: "Orbit Admin",
  },
  dashboard: {
    id: "sidebar.dashboard",
    defaultMessage: "Start",
  },
  gebruikersbeheer: {
    id: "sidebar.gebruikersbeheer",
    defaultMessage: "Gebruikersbeheer",
  },
  gebruikers: {
    id: "sidebar.gebruikers",
    defaultMessage: "Gebruikers",
  },
  gebruikersgroepen: {
    id: "sidebar.gebruikersgroepen",
    defaultMessage: "Gebruikersgroepen",
  },
  unknownappdisplayname: {
    id: "sidebar.unknownappdisplayname",
    defaultMessage: "Unknown app",
  },
  dailyappdisplayname: {
    id: "sidebar.dailyappdisplayname",
    defaultMessage: "Daily",
  },
  adminappdisplayname: {
    id: "sidebar.adminappdisplayname",
    defaultMessage: "Admin",
  },
  discoveryappdisplayname: {
    id: "sidebar.discoveryappdisplayname",
    defaultMessage: "Discovery",
  },
  loggerappdisplayname: {
    id: "sidebar.loggerappdisplayname",
    defaultMessage: "Audit",
  },
  policeappdisplayname: {
    id: "sidebar.policeappdisplayname",
    defaultMessage: "Briefing",
  },
  geocoreappdisplayname: {
    id: "sidebar.geocoreappdisplayname",
    defaultMessage: "Geo-core",
  },
  dashboardappdisplayname: {
    id: "sidebar.dashboardappdisplayname",
    defaultMessage: "Dashboard",
  },
  municipalityappdisplayname: {
    id: "sidebar.municipalityappdisplayname",
    defaultMessage: "Legger",
  },
  profielen: {
    id: "sidebar.profielen",
    defaultMessage: "Profielen",
  },
  permissies: {
    id: "sidebar.permissies",
    defaultMessage: "Permissies",
  },
});
