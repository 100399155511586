import React, { useContext } from "react";
import { injectIntl } from "react-intl";
import messages from "./messages";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { DEFAULT_LOCALE } from "../../constants";
import Login from "components/Login/Login";

const LoginView = hooksObserver(({ intl: { formatMessage } }) => {
  const {
    authStore: { doLogin },
    applicationStore: { genericSettings },
  } = useContext(StoresContext);

  const { CLIENT_PORTAL_URL } = window.env;

  return (
    <Login
      messages={{
        title: formatMessage(messages.title),
        labelEmail: formatMessage(messages.labelEmail),
        labelEmailHelper: formatMessage(messages.labelEmailHelper),
        labelPassword: formatMessage(messages.labelPassword),
        button: formatMessage(messages.button),
        forgotPassword: formatMessage(messages.forgotPassword),
      }}
      doLogin={doLogin}
      urlForgotPassword={`${CLIENT_PORTAL_URL}${DEFAULT_LOCALE}/forgotpassword`}
      enableForgotPassword={!genericSettings.find((obj) => obj.name === "adLogin")?.data?.active}
    />
  );
});

export default injectIntl(LoginView);
