import { create } from "mobx-persist";
import { Provider } from "mobx-react";
import { RouterStore } from "mobx-react-router";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { StoresContext } from "./contexts";

import AdminAuthStore from "./stores/AuthStore";
import UiStore from "./stores/UiStore";
import UserStore from "./stores/UserStore";
import ModuleStore from "stores/ModuleStore";
import ApplicationStore from "./stores/ApplicationStore";

import * as serviceWorker from "./serviceWorker";
import { setDefaultHeader } from "./services/api";
// setup all stores
const routingStore = new RouterStore();
const uiStore = new UiStore();
const userStore = new UserStore(uiStore);
const applicationStore = new ApplicationStore();
const authStore = new AdminAuthStore(uiStore);
const moduleStore = new ModuleStore();

const stores = {
  routingStore,
  uiStore,
  authStore,
  userStore,
  applicationStore,
  moduleStore,
};

const persistStores = async () => {
  const hydrate = create();
  await hydrate("uiStore", stores.uiStore);
  await hydrate("adminAuthStore", stores.authStore);
  await hydrate("userStore", stores.userStore);
  await hydrate("applicationStore", stores.applicationStore);
  await hydrate("moduleStore", stores.moduleStore);
};

const renderApp = async () => {
  ReactDOM.render(<div>loading application...</div>, document.getElementById("root"));
  await persistStores();
  const { token } = stores.authStore;
  token ? setDefaultHeader(token) : null;
  ReactDOM.render(
    <Provider {...stores}>
      {/* inject of stores for react hooks combined with mobx */}
      <StoresContext.Provider value={stores}>
        <App />
      </StoresContext.Provider>
    </Provider>,
    document.getElementById("root"),
  );
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
