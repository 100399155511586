import { observable, action, computed } from "mobx";
import { persist } from "mobx-persist";
import ApplicationModel from "./models/ApplicationModel";
import ProfileModel from "./models/ProfileModel";

export default class ApplicationStore {
  @observable
  snackError = false;

  @observable
  genericSettings = [];

  @observable
  applications = new ApplicationModel();

  @persist("object", ProfileModel)
  @observable
  profiles = new ProfileModel();

  @computed
  get apps() {
    return this.applications.applications;
  }

  @computed
  get filteredProfiles() {
    return this.profiles.filteredprofiles;
  }

  @action
  toggleSnackError = () => {
    this.snackError = !this.snackError;
  };

  @action
  setGenericSettings = (genericSettings) => {
    this.genericSettings = genericSettings;
  };
}
