import { action, observable, computed, toJS } from "mobx";

import { getProfiles, getProfile } from "../../services/api";
import Fuse from "fuse.js/dist/fuse";
import { persist } from "mobx-persist/lib/index";
import { ACTIVE_LOCALE } from "../../constants";

const optionsFuzzySearchProfile = {
  keys: ["name", "description"],
  threshold: 0.2,
};

const doFuzzySearchProfiles = (list, value) => {
  let filteredProfiles = list;
  if (value !== "") {
    let fuse = new Fuse(list, optionsFuzzySearchProfile);
    filteredProfiles = fuse.search(value);
  }
  return filteredProfiles;
};

class ProfileModel {
  @observable
  _profiles = [];

  @observable
  searchValueProfile = "";

  @observable
  activeProfile = null;

  @persist
  @observable
  rowsPerPage = 5;

  @computed
  get profiles() {
    return this._profiles;
  }

  @computed
  get filteredprofiles() {
    return doFuzzySearchProfiles(this._profiles, this.searchValueProfile);
  }

  @action
  setProfiles = (value) => {
    this._profiles = value.map((profile) => ({
      ...profile,
      name: profile["name_" + ACTIVE_LOCALE + "_BE"],
      description: profile["description_" + ACTIVE_LOCALE + "_BE"],
    }));
  };

  @action
  setActiveProfile = (value) => {
    this.activeProfile = value;
  };

  @action
  loadProfiles = async (appId) => {
    const result = await getProfiles(appId);
    this.setProfiles(result);
  };

  @action
  loadProfile = async (profileId) => {
    let result = await getProfile(profileId);
    console.log(result);
    result = { ...result, name: result["name_" + ACTIVE_LOCALE + "_BE"], description: result["description_" + ACTIVE_LOCALE + "_BE"] };
    this.setActiveProfile(result);
  };

  @action
  setSearchValueProfile = (value) => {
    this.searchValueProfile = value;
  };

  @action
  setRowsPerPage = (value) => {
    this.rowsPerPage = value;
  };
}

export default ProfileModel;
