// root auth
export const ROUTE_BASE = "/";
export const ROUTE_LOGIN = ROUTE_BASE + "login";
export const ROUTE_USERGROUPS = ROUTE_BASE + "usergroups";
// export const ROUTE_USERGROUPS_ADD = ROUTE_USERGROUPS + "/add";
export const ROUTE_USERGROUPS_DETAILS = ROUTE_USERGROUPS + "/details/:id";
export const ROUTE_USERS = ROUTE_BASE + "users";
export const ROUTE_USERS_ADD = ROUTE_USERS + "/add";
export const ROUTE_USERS_DETAILS = ROUTE_USERS + "/details/:id";
export const ROUTE_PROFILES = ROUTE_BASE + ":app/profiles";
export const ROUTE_PROFILES_DETAILS = ROUTE_PROFILES + "/details/:id";
// export const ROUTE_PERMISSIONS = ROUTE_BASE + ":app/permissions";
export const ROUTE_MUNICIPALITIES = ROUTE_BASE + ":app/municipalities";
export const ROUTE_MUNICIPALITIES_ADD = ROUTE_MUNICIPALITIES + "/add";
export const ROUTE_MUNICIPALITIES_DETAILS = ROUTE_MUNICIPALITIES + "/details/:id";
export const ROUTE_CRAB_ADDRESSES = ROUTE_BASE + ":app/crab";
export const ROUTE_NOTIFICATIONS = ROUTE_BASE + ":app/notifications";
