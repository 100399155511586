import React, { useContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { AppBar, Toolbar, Paper, Grid, Button, TextField, Typography, Checkbox, Hidden, Table, TableBody, TableCell, TablePagination, TableRow } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./ProfilesStyles";

import EnhancedTableHead from "../tableview/EnhancedTableHead";
import EnhancedTableToolbar from "../tableview/EnhancedTableToolbar";

import messages from "./messages.js";

import SearchIcon from "@material-ui/icons/Search";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { ROUTE_BASE } from "../../../routes/RouteList";
import { toJS } from "mobx";

function desc(a, b, orderBy) {
  let myA, myB;
  myA = a[orderBy].toLowerCase();
  myB = b[orderBy].toLowerCase();
  if (myB < myA) {
    return -1;
  }
  if (myB > myA) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const initialState = {
  order: "desc",
  orderBy: "name",
  selected: [],
  page: 0,
  dialog: false,
  searchValue: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "order":
      return { ...state, order: action.order };
    case "orderBy":
      return { ...state, orderBy: action.orderBy };
    case "selected":
      return { ...state, selected: action.selected };
    case "page":
      return { ...state, page: action.page };
    case "dialog":
      return { ...state, dialog: !state.dialog };
    case "searchValue":
      return { ...state, searchValue: action.searchValue };
    default:
      throw new Error();
  }
}

const Profiles = hooksObserver(({ match, intl: { formatMessage }, classes }) => {
  const {
    routingStore: { push },
    applicationStore: {
      applications: { setActiveApplication, activeapplication },
      profiles: { loadProfiles, setSearchValueProfile, rowsPerPage, setRowsPerPage },
      apps,
      filteredProfiles,
      toggleSnackError,
    },
    authStore: { forceIsLoggedInFalse },
  } = useContext(StoresContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  console.log(toJS({ filteredProfiles }));
  const rows = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: formatMessage(messages.tableheading1),
      mobileLabel: formatMessage(messages.tableheading1short),
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: formatMessage(messages.tableheading2),
      mobileLabel: formatMessage(messages.tableheading2short),
      hide: true,
    },
    {
      id: "view",
      numeric: true,
      disablePadding: false,
      label: " ",
      mobileLabel: " ",
    },
  ];

  useEffect(() => {
    const activeApp = apps.filter((app) => app.name === match.params.app);
    console.log(activeApp);
    if (activeApp.length > 0) {
      setActiveApplication(activeApp[0]);
      const fetchProfiles = async () => {
        try {
          await loadProfiles(activeApp[0].id);
        } catch (e) {
          console.log("Error::fetch profiles: ", e.toString());
          switch (e.response.status) {
            case 401:
              forceIsLoggedInFalse();
              break;
            default:
              toggleSnackError();
              console.log("TODO::fetch profiles: We still need to catch the following error: ", e.response.status);
              break;
          }
        }
      };
      fetchProfiles();
    }
  }, [apps, match.params.app]);

  useEffect(() => {
    setSearchValueProfile(state.searchValue);
  }, [state.searchValue]);

  function handleRequestSort(event, property) {
    const myOrderBy = property;
    let myOrder = "desc";

    if (state.orderBy === property && state.order === "desc") {
      myOrder = "asc";
    }

    dispatch({ type: "order", order: myOrder });
    dispatch({ type: "orderBy", orderBy: myOrderBy });
  }

  function handleChangePage(event, page) {
    dispatch({ type: "page", page: page });
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  function isSelected(id) {
    return state.selected.indexOf(id) !== -1;
  }

  function handleSearch(e) {
    dispatch({ type: "searchValue", searchValue: e.target.value });
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredProfiles.length - state.page * rowsPerPage);

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <SearchIcon className={classes.block} color="inherit" />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder={formatMessage(messages.search)}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
                value={state.searchValue}
                onChange={handleSearch}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <EnhancedTableToolbar numSelected={state.selected.length} buttonClick={null} />
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle" data-test-id={`profiles_table`}>
          <EnhancedTableHead
            numSelected={state.selected.length}
            order={state.order}
            orderBy={state.orderBy}
            onSelectAllClick={() => {}}
            onRequestSort={handleRequestSort}
            rowCount={filteredProfiles.length}
            rows={rows}
            classes={classes}
          />
          <TableBody>
            {stableSort(filteredProfiles, getSorting(state.order, state.orderBy))
              .slice(state.page * rowsPerPage, state.page * rowsPerPage + rowsPerPage)
              .map((n, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={n.id} selected={Math.abs(index % 2) === 1} className={classes.tableRow}>
                    <TableCell padding="checkbox">
                      <Checkbox checked={false} disabled={true} />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <Typography variant="body1">{n.name}</Typography>
                      <Hidden mdUp>
                        <Typography variant="caption">{n.description}</Typography>
                      </Hidden>
                    </TableCell>
                    <Hidden xsDown>
                      <TableCell className={classes.tableCell} align="left">
                        <Typography variant="body1">{n.description}</Typography>
                      </TableCell>
                    </Hidden>
                    <TableCell className={classes.tableCell} align="right">
                      <Button
                        className={classes.detailButton}
                        color="primary"
                        size="small"
                        variant="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          push(`${ROUTE_BASE + match.params.app}/profiles/details/${n.id}`);
                        }}
                      >
                        <Hidden xsDown>{formatMessage(messages.details)} </Hidden>
                        <ChevronRightIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 60 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredProfiles.length}
        rowsPerPage={rowsPerPage}
        page={state.page}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={formatMessage(messages.rowsamount)}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
      />
    </Paper>
  );
});

export default injectIntl(withStyles(styles)(Profiles));
