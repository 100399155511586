import { observable, action } from "mobx";
import { login, logout } from "../services/auth";
import { persist } from "mobx-persist";

export default class AdminAuthStore {
  @persist
  @observable
  isLoggedIn = false;

  @persist("object")
  @observable
  user = {};

  @persist
  @observable
  token = null;

  constructor(uiStore) {
    this.uiStore = uiStore;
  }
  /**
   * returns logged in status
   */
  @action
  doLogin = async (email, password) => {
    const { user, token } = await login(email, password);

    // if language is undefined set user language from
    // user langugage
    if (!this.uiStore.locale) {
      this.uiStore.setLocale(user.language);
    }

    this.user = user;
    this.token = token;

    this.isLoggedIn = true;
    return true;
  };

  /**
   * returns logged in status
   */
  @action
  doLogout = async () => {
    this.forceIsLoggedInFalse();
    await logout();
  };

  @action
  forceIsLoggedInFalse = () => {
    this.user = {};
    this.token = null;
    this.isLoggedIn = false;
  };
}
